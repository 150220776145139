import React from "react"
import cx from "classnames"

type VariantType = "error"

interface BannerProps {
  variant?: VariantType
  heading: string
  description: string
}

const variantIconMap = {
  error: <i className="fas fa-exclamation-triangle text-red-500" />,
}

export const Banner = ({
  variant = "error",
  heading,
  description,
}: BannerProps) => {
  const headingClass = cx("text-sm font-medium text-red-800", {
    "text-red-800": variant === "error",
  })

  const descriptionClass = cx("mt-1 text-sm", {
    "text-red-700": variant === "error",
  })

  return (
    <div className="rounded-md bg-red-50 p-4">
      <div className="flex">
        <div className="shrink-0">{variantIconMap[variant]}</div>
        <div className="ml-3">
          <h3 className={headingClass}>{heading}</h3>
          {description && <div className={descriptionClass}>{description}</div>}
        </div>
      </div>
    </div>
  )
}
