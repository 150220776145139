import React from "react"
import { ReactNode, useReducer } from "react"

import { partnersReducer } from "../reducers/partnersReducer"
import { initialState, PartnersContext } from "./PartnersContext"

interface PartnersProviderTypes {
  children: ReactNode
}

const PartnersProvider = ({ children }: PartnersProviderTypes) => {
  const [state, dispatch] = useReducer(partnersReducer, initialState)

  return (
    <PartnersContext.Provider value={{ state, dispatch }}>
      {children}
    </PartnersContext.Provider>
  )
}

export default PartnersProvider
