import { PARTNERS_ACTIONS } from "../shared/constants"
import { StateTypes, ActionType } from "../context/PartnersContext"

export const partnersReducer = (
  state: StateTypes,
  { type, payload }: ActionType
) => {
  const actions = {
    [PARTNERS_ACTIONS.setActiveRow]: {
      ...state,
      activeRow: payload,
    },
    [PARTNERS_ACTIONS.setActiveTab]: {
      ...state,
      activeTab: payload,
    },
    [PARTNERS_ACTIONS.setEligibleActiveRowUnlocked]: {
      ...state,
      eligibleActiveRowUnlocked: payload,
    },
    [PARTNERS_ACTIONS.setViewport]: {
      ...state,
      viewport: payload,
    },
    [PARTNERS_ACTIONS.setDealOwnerRowId]: {
      ...state,
      dealOwnerRowId: payload,
    },
    [PARTNERS_ACTIONS.setEligibleLeadDataLoaded]: {
      ...state,
      eligibleLeadDataLoaded: payload,
    },
    [PARTNERS_ACTIONS.setQualifiedLeadColumnFilters]: {
      ...state,
      qualifiedLeadColumnFilters: payload,
    },
    [PARTNERS_ACTIONS.setQualifiedLeadFilters]: {
      ...state,
      qualifiedLeadFilters: payload,
    },
    [PARTNERS_ACTIONS.setQualifiedLeadFilterFunctions]: {
      ...state,
      qualifiedLeadFilterFunctions: payload,
    },
    [PARTNERS_ACTIONS.setQualifiedLeadSorting]: {
      ...state,
      qualifiedLeadSorting: payload,
    },
    [PARTNERS_ACTIONS.setEligibleLeadColumnFilters]: {
      ...state,
      eligibleLeadColumnFilters: payload,
    },
    [PARTNERS_ACTIONS.setEligibleLeadFilters]: {
      ...state,
      eligibleLeadFilters: payload,
    },
    [PARTNERS_ACTIONS.setEligibleLeadFilterFunctions]: {
      ...state,
      eligibleLeadFilterFunctions: payload,
    },
    [PARTNERS_ACTIONS.setEligibleLeadSorting]: {
      ...state,
      eligibleLeadSorting: payload,
    },
    default: state,
  }
  return actions[type] || actions.default
}
