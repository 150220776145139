import { ReactNode } from "react"
import assert from "assert"

import { Banner } from "../../components/Banner"
import { Spinner } from "../../components/Spinner"
import NothingFound from "../../components/NothingFound"
import { QueryStatus } from "@tanstack/react-query"

interface PartnersTypes {
  children: ReactNode
  status: QueryStatus
  isLoading: boolean
  isForbidden: boolean
  contentBg: string
}

const Partners = ({
  children,
  status,
  isLoading,
  isForbidden,
  contentBg,
}: PartnersTypes) => {
  let content
  const statusArr = [...(Array.isArray(status) ? status : [status])]
  const isLoadingArr = [...(Array.isArray(isLoading) ? isLoading : [isLoading])]

  if (isForbidden) {
    content = (
      <NothingFound
        title="Unauthorized access!"
        text={
          <span>
            You haven't registered as an NCX partner or are not associated with
            any program partners. Please reach out to{" "}
            <a href="mailto:support@ncx.com" className="link">
              support@ncx.com
            </a>{" "}
            for next steps.
          </span>
        }
        linkText="Get support"
        to="mailto:support@ncx.com"
      />
    )
  } else if (isLoadingArr.includes(true)) {
    content = (
      <div className="flex-auto flex items-center justify-center">
        <Spinner />
      </div>
    )
  } else if (statusArr.includes("error")) {
    content = (
      <div className="container p-4">
        <Banner
          heading="An error occurred while fetching your information."
          description="Please refresh the page to try again."
        />
      </div>
    )
  } else {
    assert(
      statusArr
        .filter((status) => !!status)
        .every((status) => status === "success"),
      `Unexpected status found in ${JSON.stringify(statusArr)}`
    )
    content = (
      <div className="container">
        <div className="inner-container max-w-full mx-auto pb-6 md:pb-12 pt-6 md:pt-12">
          {children}
        </div>
      </div>
    )
  }

  return (
    <div className={`flex-auto flex flex-col bg-${contentBg}`}>{content}</div>
  )
}

export default Partners
