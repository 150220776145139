import { useContext, useEffect } from "react"
import { useQueryClient } from "@tanstack/react-query"
import { useDialogState } from "reakit"

import PartnersLayout from "../_layouts/Partners"
import InviteDealOwnerModal from "../../sections/Partners/PartnersTable/InviteDealOwnerModal"
import EditDealOwnerModal from "../../sections/Partners/PartnersTable/EditDealOwnerModal"
import PartnersTabs from "../../sections/Partners/PartnersTabs"
import { PartnersContext } from "../../context/PartnersContext"
import {
  useGetPartnersDashboard,
  useGetPartnerAssignees,
  useProfile,
  useProjectReachoutDashboardView,
  useProjectPartnersReachoutsCounter,
} from "../../hooks"
import { PARTNERS_ACTIONS, ELIGIBLE_LEADS } from "../../shared/constants"

const Partners = () => {
  const queryClient = useQueryClient()
  const {
    state: {
      dealOwnerRowId,
      eligibleLeadSorting,
      eligibleLeadColumnFilters,
      eligibleLeadFilterFunctions,
    },
    dispatch,
  } = useContext(PartnersContext)

  const inviteDealOwnerDialog = useDialogState({ animated: true })
  const editDealOwnerDialog = useDialogState({ animated: true })

  const {
    data: profile,
    status: profileStatus,
    isLoading: profileIsLoading,
  } = useProfile(queryClient)

  const {
    data: partnerDashboardData,
    status: partnerDashboardStatus,
    isLoading: partnerDashboardIsLoading,
    error: partnerDashboardError,
  } = useGetPartnersDashboard(queryClient)

  const { data: eligibleLeads, status: eligibleLeadsStatus } =
    useProjectReachoutDashboardView(
      queryClient,
      eligibleLeadSorting,
      eligibleLeadColumnFilters,
      eligibleLeadFilterFunctions,
      {
        enabled: !!profile?.is_partner,
      }
    )

  const { data: reachoutsCounter, status: reachoutsCounterStatus } =
    useProjectPartnersReachoutsCounter(queryClient, {
      enabled: profile?.is_partner,
    })

  const {
    data: partnerAssignees,
    status: partnerAssigneesStatus,
    isLoading: partnerAssigneesIsLoading,
  } = useGetPartnerAssignees(queryClient)

  const dealOwnerPartner = partnerDashboardData?.find(
    (partner) => partner.id === dealOwnerRowId
  )

  // DEV: Eligible leads data takes longer and is not the first view so we don't wait to show the main page while that data is loading.
  // instead we show a loader in the eligible leads tab if the data isn't loaded yet.
  useEffect(() => {
    const loadingStatuses = ["pending"]
    if (
      loadingStatuses.indexOf(reachoutsCounterStatus) === -1 &&
      loadingStatuses.indexOf(eligibleLeadsStatus) === -1
    ) {
      dispatch({
        type: PARTNERS_ACTIONS.setEligibleLeadDataLoaded,
        payload: true,
      })
    }
  }, [dispatch, reachoutsCounterStatus, eligibleLeadsStatus])

  useEffect(() => {
    queryClient.refetchQueries({
      queryKey: ["partners", ELIGIBLE_LEADS],
      type: "active",
      exact: true,
    })
  }, [eligibleLeadSorting, eligibleLeadColumnFilters, queryClient])

  return (
    <PartnersLayout
      isLoading={[
        partnerDashboardIsLoading,
        profileIsLoading,
        partnerAssigneesIsLoading,
      ]}
      status={[partnerDashboardStatus, profileStatus, partnerAssigneesStatus]}
      isForbidden={partnerDashboardError?.response?.status === 403}
    >
      <h1 className="text-2xl lg:text-3xl mb-5 lg:mb-4.5">Partners</h1>

      <PartnersTabs
        partnerDashboardData={partnerDashboardData}
        eligibleLeads={eligibleLeads}
        profile={profile}
        inviteDealOwnerDialog={inviteDealOwnerDialog}
        editDealOwnerDialog={editDealOwnerDialog}
        reachoutsCounter={reachoutsCounter}
      />

      <InviteDealOwnerModal
        dialog={inviteDealOwnerDialog}
        dealOwnerRowId={dealOwnerRowId}
        partnerAssignees={partnerAssignees?.partner_assignees}
      />

      {dealOwnerPartner ? (
        <EditDealOwnerModal
          dialog={editDealOwnerDialog}
          dealOwnerRowId={dealOwnerRowId}
          partnerAssignees={partnerAssignees?.partner_assignees}
          dealOwnerPartner={dealOwnerPartner}
        />
      ) : null}
    </PartnersLayout>
  )
}

export default Partners
